import { AfterViewChecked, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Languages } from '../../../common/enums/languages.enum';
import { LanguageService } from '../../../core/providers/language/language.service';
import { LanguageDropdownComponent } from '../language-dropdown/language-dropdown.component';
import { LanguageSelectorService } from '../language-selector.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent implements OnInit, OnDestroy, AfterViewChecked {
  @ViewChild('dropdownList')
  dropdownListRef: ElementRef;
  @ViewChild(LanguageDropdownComponent)
  dropdown: LanguageDropdownComponent;

  activeLanguageIcon: string;
  languagesVisible: boolean;

  private onDestroy$ = new Subject<void>();

  constructor(private languageService: LanguageService, private changeDetectionRef: ChangeDetectorRef) {}

  showDropdown() {
    this.dropdown.show();
  }

  getAvailableLanguages$(): Observable<Languages[]> {
    return this.languageService.getAvailableLanguages$();
  }

  activateLanguage(language: Languages) {
    this.languageService.activateLanguage(language);
    this.dropdown.hide();
  }

  getLanguageIcon(language: Languages): string {
    return LanguageSelectorService.getLanguageIcon(language);
  }

  ngOnInit() {
    this.getActiveLanguage$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(language => {
        this.activeLanguageIcon = this.getLanguageIcon(language);
        this.changeDetectionRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngAfterViewChecked(): void {
    if (this.dropdownListRef) {
      const posBounding = 70;
      const maxHeightValue = window.innerHeight - posBounding;

      this.dropdownListRef.nativeElement.style.maxHeight = `${maxHeightValue}px`;
    }
  }

  private getActiveLanguage$(): Observable<Languages> {
    return this.languageService.getActiveLanguage$();
  }
}
