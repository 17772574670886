<app-dialog>
  <app-dialog-content>
    <h4 class="mb-20">
      <b>{{ data.headerText }}</b>
    </h4>
    <div class="AlertDialog__contentText">{{ data.contentText }}</div>
  </app-dialog-content>
  <app-dialog-actions class="AlertDialog__content" fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="center center">
    <button
      type="button"
      class="btn mb-10"
      [attr.aria-label]="data.closeBtnText | translate"
      (click)="hideModal()"
      [style.background-color]="appColor">
      {{ data.closeBtnText | translate }}
    </button>
  </app-dialog-actions>
</app-dialog>
