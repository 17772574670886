import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CONSTANTS_ROUTING } from '../common/constants/routing/routing.constants';
import { CAMPAIGN } from './campaign/campaign.constants';
import { CONTACT_GUEST_CONSTANTS } from './contact-guest/contact-guest.constants';
import { DEMOGRAPHICS_CONSTANTS } from './demographics/demographics.constants';
import { LayoutComponent } from './layout/layout/layout.component';
import { ONE_MORE_QUESTION_CONTANTS } from './one-more-question/one-more-question.constants';
import { QR_LANDING_CONSTANTS } from './qr-landing/qr-landing.constants';
import { VOUCHER_ROUTING_CONSTANTS } from './voucher/voucher-routing.constant';
import { WHEEL_OF_FORTUNE_ROUTING_CONSTANTS } from './wheel-of-fortune/wheel-of-fortune-routing.constants';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: CONSTANTS_ROUTING.ROUTES.SURVEY,
        component: LayoutComponent,
        loadChildren: () => import('./survey/survey.module').then(module => module.SurveyModule),
      },
      {
        path: CONSTANTS_ROUTING.ROUTES.VENUES,
        component: LayoutComponent,
        loadChildren: () => import('./venues/venues.module').then(module => module.VenuesModule),
      },
      {
        path: CONSTANTS_ROUTING.ROUTES.CLOSED,
        component: LayoutComponent,
        loadChildren: () => import('./closed-page/closed-page.module').then(module => module.ClosedPageModule),
      },
      {
        path: CONSTANTS_ROUTING.ROUTES.NOT_FOUND,
        component: LayoutComponent,
        loadChildren: () => import('./not-found-page/not-found-page.module').then(module => module.NotFoundPageModule),
      },
      {
        path: CONSTANTS_ROUTING.ROUTES.GHOST,
        component: LayoutComponent,
        loadChildren: () => import('./not-found-page/not-found-page.module').then(module => module.NotFoundPageModule),
      },
      {
        path: CONSTANTS_ROUTING.ROUTES.ADD.MAIN,
        component: LayoutComponent,
        loadChildren: () => import('./ad-pages/ad-pages.module').then(module => module.AdPagesModule),
      },
      {
        path: WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.MAIN,
        component: LayoutComponent,
        loadChildren: () => import('./wheel-of-fortune/wheel-of-fortune.module').then(module => module.WheelOfFortuneModule),
      },
      {
        path: VOUCHER_ROUTING_CONSTANTS.ROUTES.MAIN,
        component: LayoutComponent,
        loadChildren: () => import('./voucher/voucher.module').then(module => module.VoucherModule),
      },
      {
        path: CONTACT_GUEST_CONSTANTS.ROUTES.MAIN,
        component: LayoutComponent,
        loadChildren: () => import('./contact-guest/contact-guest.module').then(module => module.ContactGuestModule),
      },
      {
        path: DEMOGRAPHICS_CONSTANTS.ROUTES.MAIN,
        component: LayoutComponent,
        loadChildren: () => import('./demographics/demographics.module').then(module => module.DemographicsModule),
      },
      {
        path: `${QR_LANDING_CONSTANTS.ROUTES.MAIN}/:id`,
        component: LayoutComponent,
        loadChildren: () => import('./qr-landing/qr-landing.module').then(module => module.QrLandingModule),
      },
      {
        path: ONE_MORE_QUESTION_CONTANTS.ROUTES.FORM,
        component: LayoutComponent,
        loadChildren: () => import('./one-more-question/one-more-question.module').then(module => module.OneMoreQuestionModule),
      },
      {
        path: CAMPAIGN.ROUTES.FORM,
        component: LayoutComponent,
        loadChildren: () => import('./campaign/campaign.module').then(module => module.CampaignModule),
      },
      {
        path: CONSTANTS_ROUTING.ROUTES.VENUE_DISABLED,
        component: LayoutComponent,
        loadChildren: () => import('./venue-disabled-page/venue-disabled-page.module').then(module => module.VenueDisabledPageModule),
      },
      {
        path: CONSTANTS_ROUTING.ROUTES.ERROR,
        component: LayoutComponent,
        loadChildren: () => import('./error-pages/error-pages.module').then(module => module.ErrorPagesModule),
      },
      {
        path: '**',
        component: LayoutComponent,
        loadChildren: () => import('./empty-page/empty-page.module').then(module => module.EmptyPageModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
