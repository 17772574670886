import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MaterialModule } from 'src/app/material/material.module';
import { CoreModule } from '../../core/core.module';
import { SharedModule } from '../../shared/shared.module';
import { LanguageSelectorModule } from '../language-selector/language-selector.module';
import { ContentComponent } from './content/content.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { LayoutComponent } from './layout/layout.component';

@NgModule({
  declarations: [HeaderComponent, FooterComponent, ContentComponent, LayoutComponent],
  imports: [CommonModule, CoreModule, LanguageSelectorModule, SharedModule, MaterialModule, RouterModule, FlexLayoutModule],
  exports: [HeaderComponent, FooterComponent, ContentComponent],
})
export class LayoutModule {}
