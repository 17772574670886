import { Injectable } from '@angular/core';
import FP, { Agent, ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro';
import * as FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CONSTANTS } from '../../../common/constants/constants';
import { UtilsService } from '../util/utils.service';

@Injectable()
export class FingerprintService {
  private fingerprint$: BehaviorSubject<ExtendedGetResult> = new BehaviorSubject(null);

  constructor(private utilsService: UtilsService) {}

  getFingerprint$(): Observable<ExtendedGetResult> {
    const tokenQA = localStorage.getItem(environment.fpQA);

    if (environment.fH && tokenQA === environment.fH.toString()) {
      return of({ visitorId: localStorage.getItem('testFingerprint'), requestId: new Date().getTime().toString() } as ExtendedGetResult);
    }
    // if (this.isFpCorrect()) {
    //   const visitorId = localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEYS.FP_FOODBACK_VAL);
    //
    //   return of({ visitorId } as ExtendedGetResult);
    // }

    return this.fingerprint$.asObservable().pipe(
      tap((val: ExtendedGetResult) => (val?.visitorId ? null : from(this.getFingerprint()))),
      filter((val: ExtendedGetResult) => val !== null)
    );
  }

  private async getFingerprint(): Promise<any> {
    let fp : Agent;
    if (environment.production) {
      fp = await FingerprintJS.load({
        apiKey: environment.fingerprintToken,
        scriptUrlPattern: [
          environment.fingerprintProxyScript,
          FingerprintJS.defaultScriptUrlPattern
        ],
        region: 'eu',
        endpoint: [
          environment.fingerprintProxyEndpoint,
          FingerprintJS.defaultEndpoint
        ]
      })
    } else {
      fp = await FP.load({
        token: environment.fingerprintToken,
        region: 'eu',
        endpoint: environment.fingerprintEndpoint,
      });
    }

    try {
      const fingerprint: ExtendedGetResult = await fp.get({
        extendedResult: true,
      });

      this.setFingerPrintInLocalStorages(fingerprint.visitorId);
      this.fingerprint$.next(fingerprint);
    } catch (error) {
      if (error.message === FP.ERROR_CLIENT_TIMEOUT) {
        console.error('FP Timeout Error');
      }
    }
  }

  private setFingerPrintInLocalStorages(visitorId: string): void {
    localStorage.setItem(CONSTANTS.LOCALSTORAGE_KEYS.FP_FOODBACK, this.utilsService.getHashCode(this.getKeyValue(visitorId)).toString());
    localStorage.setItem(CONSTANTS.LOCALSTORAGE_KEYS.FP_FOODBACK_VAL, visitorId);
  }

  private getKeyValue(visitorId: string): string {
    return `${visitorId}&${environment.alHp}&${visitorId}${environment.alHp}`;
  }

  private isFpCorrect(): boolean {
    const visitorId = localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEYS.FP_FOODBACK_VAL);

    return this.utilsService.isHashKeyCorrect(this.getKeyValue(visitorId), CONSTANTS.LOCALSTORAGE_KEYS.FP_FOODBACK);
  }

  private getVisitorIdFromCache(keyValue: string): string {
    return keyValue.split('&')[1];
  }

  // private getRegion(): Region {
  //   return moment.tz.guess().includes('Europe') ? 'eu' : 'us';
  // }
}
