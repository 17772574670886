import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '../core/core.module';
import { FingerprintService } from '../core/providers/fingerprint/fingerprint.service';
import { GenericErrorHandlerService } from '../core/providers/generic-error-handler/generic-error-handler.service';
import { GoogleAnalyticsService } from '../core/providers/google-analytics/google-analytics.service';
import { HttpService } from '../core/providers/http/http.service';
import { TokenService } from '../core/providers/token/token.service';
import { MaterialModule } from '../material/material.module';
import { FilesModule } from './advertisement/files.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CampaignResolver } from './campaign/campaign.resolver';
import { LayoutModule } from './layout/layout.module';
import { SurveyInterceptor } from './survey.interceptor';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, CoreModule, AppRoutingModule, LayoutModule, FilesModule, BrowserAnimationsModule, MaterialModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: SurveyInterceptor, multi: true },
    HttpService,
    FingerprintService,
    GoogleAnalyticsService,
    TokenService,
    GenericErrorHandlerService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
