<app-basic-page [headerText]="headerText" [message]="message">
  <div class="mt-20 mb-20 FinalPage_container">
    <svg viewBox="0 0 100 100" *ngIf="advUrl === ''">
      <rect width="100" height="100" style="fill: rgb(255, 255, 255)" />
    </svg>
    <img
      class="FinalPage_advertisement"
      [ngClass]="{ 'fade-in': isImageLoaded, 'FinalPage_advertisement-hover': getUrl() }"
      (load)="onImageLoad()"
      [src]="advUrl"
      (click)="goTotAdvertisementUrl()"
      (error)="onImageError()"
      *ngIf="advUrl !== ''" />
    <!-- <app-button *ngIf="token" (click)="goToPortal()" [text]="'SYSTEM.HOME' | translate" icon="home" classes="btn-wide mt-20"></app-button> -->
  </div>
  <ng-content></ng-content>
</app-basic-page>
