export const venues_without_fraud_detection = [
  //HOFF
  '7db14a0f-81ac-4677-9c33-1b0c790842ae',
  'd9b2c6a2-cc1f-4fd9-9106-ca7e131d7f19',
  '2f6c8607-5258-4a0b-b023-8bcb5f098549',
  'a4c5965a-73a5-4979-a024-d2291c823ac5',
  '8fcbf5a9-ced6-4f72-bd24-6596df99ac7b',
  '6dc84cab-fd0b-4be3-ac67-a434824a0807',
  '59400ac6-8013-4330-94ae-3a4c70ff8ab3',
  'd06714ae-3c46-4099-ad6c-7a73de416f5f',
  'a0b6cd62-bd56-4dd8-918d-6b0043e7abfa',
  'aa961ea2-3e48-475f-bec2-8b6d8e81de56',
  '87f79796-7b02-4e5b-90f8-dd70f4e33ba2',
  '79190c46-5132-4901-84c0-dbfaba5e6419',
  'd7a6f030-23a6-4338-bc96-1161d42b1d47',
  '742c05be-d28a-4027-bfd8-60c4c00f16ec',
  'e6a1dfe2-e899-4507-bef1-4ce9381efd94',
  '38dffbbd-1c7c-4863-9cee-da6abd527fce',
  'a5e3df00-0aad-468a-a958-4e24b81c9b4f',
  '1ee53671-f3f5-4fa1-abd8-3618f446d878',
  'd594d0ad-daec-4825-9eef-65c9db37db06',
  //ORKLA
  'b1265b68-4426-4926-88fd-e3d280e1492c',
  'e25ef9b0-6696-494d-9dec-455b00bfcc71',
  '9b68c325-d5a7-4be7-b04b-8e3b53611216',
  'cb0be016-d261-4381-8491-7faedb1edf5f',
  '9f71a9df-1476-44b1-87a3-de729a2d9a75',
  'a2d2db8a-ca27-44ed-b83f-7278226dc8c7',
  '7d84c75f-c1f9-4308-bea3-2a5ddb5daf7e',
  'deb77ab8-7ec3-4c89-9eb0-38cbd563efdc',
  'd6dfdb05-e76f-453f-81b9-3da9082770cb',
  'f39fcca9-1219-47c9-9772-55c8fcdbb177',
  'a7570020-e4db-464a-8a69-cc2c5eda6f18',
  'c34c7e3e-0d4d-4692-bd39-389ca9ef7127',
  'dab0cce1-1283-4dcc-830a-749e23f3324a',
  'd51e7641-baf6-4420-88d9-46c54f45f62b',
  '953788b4-4d04-4624-8f71-29240dea8c37',
  '80aa1666-a52f-497a-88d7-c6c477cb22a6',
  'd055fe67-7018-4493-a17f-d6def617d704',
  '9aacad79-1b4b-467e-ba56-f68b040fceeb',
  'd6d5d28e-e598-4906-aecf-38cded88355e',
  // EDO JAPAN
  '3da6841c-a4cd-46d8-9c65-e35a2de7b5ae',
  //UAT
  '7f2e386b-5a29-4842-9cae-2b68861bc34b',
  '4a4bce02-7272-437e-8cc5-07f75ea204d4',
  '4d2b7556-2b57-4191-abcf-91ec5affad51',
  '0c38b0e6-ebef-4dea-bd8a-fefeded84f38',
  '7f2e386b-5a29-4842-9cae-2b68861bc34b',
  '891fcd54-d934-40bc-aa39-1cc9697fb67c',
  '9fd201e1-8938-4e77-aa2c-a18e4e4e58c0',
  '1f162840-7d14-43fe-9607-e695442e1249',
  //RC Show 2023
  '437390bd-7009-46ee-a208-dfe09669cd41',
  'af8d1305-b77a-4103-bb2d-d16904e9661e',
  '27d57dbf-156a-43d8-a58b-5da92b76c455',
  '69b7f53f-0f6b-4dd6-a989-340542e1e5c5',
  '38d9133f-1550-4335-93b5-693eb7b97b06',
  'c2dccd28-c291-41c5-80a0-338f36a0db79',
  '2071d646-5cb9-4a99-94f5-a788806db3be',
  '9a8fdb91-175a-4e46-8e2a-d62a6a8605b4',
  // Q-Meieriene
  '94a324d9-b910-44fb-af1b-c344273fe50b',
  '3037a7bc-e0ff-41f1-8aab-e61797af8632',
  'f873c3d8-30e1-4560-a389-d9be0f1a7b37',
  '8d3cb2a4-264c-4d36-acae-5eeefa51f35e',
  'c7734923-5b3d-434b-a1fc-f26ab14f4299',
  '3ef08842-88e3-48ff-a544-3d8c15ef92c6',
  'a9d3130f-d5e7-44c0-9768-bc31a3e5ff3f',
  '3f720132-fc13-4af7-ad28-9f6e5c7d914e',
  '3a510153-22c0-4b0c-be5f-5ad7a495eb74',
  //RC Show 2024
  '6d5b9549-6f9a-4860-8b4f-466c182240e8',
  '299ff417-49c5-4d8f-aa9f-af977afb5165',
  '07fa45cb-6950-438a-800e-cbb45399db8a',
  '07687f63-177e-4ca1-b032-9be402da20c2',
  '10442970-8047-4e97-bcc5-661ce8dcea0c',
  '8bd227be-fb17-4f3c-beba-67a04e5d8924',
  'df888fee-4479-4f73-bf5b-c971e90e81de',
  '52aae43e-9ebf-48bb-bdfe-281815c7bd13',
  'ad038f20-0160-4949-918c-d2c9ad718338',
  '2e57719d-fd90-4e41-b78a-22c41a4c788d',
  '2d0adecf-73a0-4091-b7a1-e35d32a77f58',
  'ec0039c8-e2e7-4c29-8829-a38be4b406da',
];
