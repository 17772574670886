import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilsService } from '../../core/providers/util/utils.service';
import { FilesService } from './files.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [FilesService, UtilsService],
})
export class FilesModule {}
