<div class="AppComponent">
  <div class="AppComponentContentWrapper">
    <app-layout-header *ngIf="isLoaded"></app-layout-header>
    <app-layout-content class="AppComponentContentWrapperContent">
      <router-outlet></router-outlet>
    </app-layout-content>
    <app-layout-footer class="AppComponentFooter" *ngIf="isLoaded"></app-layout-footer>
    <div *ngIf="!isLogoLoaded" class="loader-content" fxLayout="row" fxLayoutAlign="center center">
      <mat-spinner diameter="100" #spinnerRef></mat-spinner>
    </div>
  </div>
</div>
