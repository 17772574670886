<div class="PhoneCodeSelect-codeListContainer">
  <div class="PhoneCodeSelect-countryCode PhoneCodeSelect-inputBorder"
       [ngClass]="{'InputSelected': isCountryCodesListVisible}"
       fxLayout="row" fxLayoutAlign="space-between center"
       appOutsideClick
       (outsideClick)="closeSelect()"
       (click)="onListClick()">
    <img [src]="'assets/img/all-flags/'+ selectedCountryCode.icon + '.svg'"
         class="PhoneCodeSelect-flag"/>
    <span class="mr-10">{{selectedCountryCode.dialCode}}</span>
    <mat-icon>arrow_drop_down</mat-icon>
  </div>
  <div class="PhoneCodeSelect-codeList"
       *ngIf="isCountryCodesListVisible" #countryCodeListRef>
    <div class="PhoneCodeSelect-countryCode"
         fxLayout="row" fxLayoutAlign="space-between center"
         *ngFor="let countryCode of countryPhoneCodes; trackBy: trackByCountryCode"
         [ngClass]="{'PhoneCodeSelect-countryCodeSelected':
                  countryCode.dialCode === selectedCountryCode.dialCode && countryCode.icon === selectedCountryCode.icon}"
         (click)="selectCountryCode(countryCode)">
      <img [src]="'assets/img/all-flags/'+ countryCode.icon + '.svg'"
           class="PhoneCodeSelect-flag"/>
      <span class="mr-10">{{countryCode.dialCode}}</span>
    </div>
  </div>
</div>
