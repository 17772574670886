import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '../material/material.module';
import * as Components from './components';
import { DialogService } from './components/dialog/dialog.service';
import * as Directives from './directives';

@NgModule({
  declarations: [
    Components.BasicPageComponent,
    Components.ButtonBasicComponent,
    Components.ButtonMenuChoiceComponent,
    Components.ButtonOutlineComponent,
    Components.EmptyPageComponent,
    Components.FinalPageComponent,
    Components.ForbiddenPageComponent,
    Components.SvgFoodbackArrowRightComponent,
    Components.SvgFoodbackHappyComponent,
    Components.SvgFoodbackSadComponent,
    Components.SvgFoodbackDisableComponent,
    Components.SvgFoodbackEnableComponent,
    Components.DialogComponent,
    Components.ConfirmDialogComponent,
    Components.AlertDialogComponent,
    Components.ClosedPageComponent,
    Components.CheckInDialogComponent,
    Components.FormButtonsComponent,
    Components.LoaderComponent,
    Components.PhoneCodeSelectComponent,
    Components.SurveyStatementComponent,
    Directives.OutsideClickDirective,
    Directives.AutocompleteDirective,
    Directives.DialogActionsDirective,
    Directives.DialogContentDirective,
    Directives.DialogTitleDirective,
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, MaterialModule, FlexLayoutModule],
  providers: [DialogService],
  exports: [
    Components.BasicPageComponent,
    Components.ButtonBasicComponent,
    Components.ButtonMenuChoiceComponent,
    Components.ButtonOutlineComponent,
    Components.EmptyPageComponent,
    Components.FinalPageComponent,
    Components.ForbiddenPageComponent,
    Components.SvgFoodbackArrowRightComponent,
    Components.SvgFoodbackHappyComponent,
    Components.SvgFoodbackSadComponent,
    Components.SvgFoodbackDisableComponent,
    Components.SvgFoodbackEnableComponent,
    Components.DialogComponent,
    Components.ClosedPageComponent,
    Components.FormButtonsComponent,
    Components.LoaderComponent,
    Components.PhoneCodeSelectComponent,
    Components.SurveyStatementComponent,
    Directives.OutsideClickDirective,
    Directives.AutocompleteDirective,
    Directives.DialogActionsDirective,
    Directives.DialogContentDirective,
    Directives.DialogTitleDirective,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
  ],
})
export class SharedModule {}
