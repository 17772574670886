import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as fromAlertDialog from '../dialog/dialogs/alert-dialog/alert-dialog.component';
import * as fromCheckInDialog from '../dialog/dialogs/check-in-dialog/check-in-dialog.component';
import * as fromConfirmDialog from '../dialog/dialogs/confirm-dialog/confirm-dialog.component';
import { IAlertDialog } from './dialogs/alert-dialog/alert-dialog.interface';
import { ICheckInDialogInterface } from './dialogs/check-in-dialog/check-in.dialog.interface';
import { IConfirmDialog } from './dialogs/confirm-dialog/confirm.dialog.interface';

@Injectable()
export class DialogService {
  constructor(private matDialog: MatDialog) {}

  confirmDialog(data: IConfirmDialog) {
    return this.matDialog.open(fromConfirmDialog.ConfirmDialogComponent, {
      data,
      ...fromConfirmDialog.CONFIRM_DIALOG_CONFIG,
    });
  }

  checkInDialog(data: ICheckInDialogInterface) {
    return this.matDialog.open(fromCheckInDialog.CheckInDialogComponent, {
      data,
      ...fromCheckInDialog.CHECKIN_DIALOG_CONFIG,
    });
  }

  alertDialog(data: IAlertDialog) {
    return this.matDialog.open(fromAlertDialog.AlertDialogComponent, {
      data,
      ...fromAlertDialog.ALERT_DIALOG_CONFIG,
    });
  }
}
