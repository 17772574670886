import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { LayoutService } from '../../../core/providers/layout/layout.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements AfterViewChecked, OnInit {
  @ViewChild('spinnerRef') spinnerRef: any;
  isLoaded = false;
  isLogoLoaded = false;

  constructor(private layoutService: LayoutService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.layoutService.loadedLayoutData$.subscribe(isLoaded => {
      this.isLoaded = isLoaded;
      this.cdr.detectChanges();
    });
    this.layoutService.loadedLogo$.subscribe(isLoaded => {
      this.isLogoLoaded = isLoaded;
      this.cdr.detectChanges();
    });
  }

  ngAfterViewChecked(): void {
    if (this.spinnerRef && this.spinnerRef._elementRef && this.spinnerRef._elementRef.nativeElement) {
      (document.getElementsByClassName('mdc-circular-progress__indeterminate-circle-graphic')[0] as any).style.stroke =
        this.layoutService.getDefaultColor();
    }
  }
}
