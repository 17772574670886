import { HttpClient } from '@angular/common/http';
import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { catchError, map, takeUntil } from 'rxjs/operators';
import { CONSTANTS } from '../../../common/constants/constants';
import { Languages } from '../../../common/enums/languages.enum';
import { ICountryCodeSelectInterface } from '../../../components/qr-landing/qr-landing-check-in/country-code-select.interface';
import { LanguageService } from '../../../core/providers/language/language.service';

@Component({
  selector: 'app-phone-code-select',
  templateUrl: './phone-code-select.component.html',
  styleUrls: ['./phone-code-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneCodeSelectComponent implements AfterViewChecked, OnInit, OnDestroy {
  @ViewChild('countryCodeListRef') countryCodeListRef: ElementRef;
  @Output() readonly selectedCode: EventEmitter<ICountryCodeSelectInterface> = new EventEmitter<ICountryCodeSelectInterface>();
  isCountryCodesListVisible = false;
  selectedCountryCode: ICountryCodeSelectInterface = {
    dialCode: '+47',
    icon: 'no',
  };
  countryPhoneCodes: ICountryCodeSelectInterface[] = [];
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(private cdRef: ChangeDetectorRef, private languageService: LanguageService, private http: HttpClient) {}

  ngOnInit(): void {
    this.getCountriesPhoneCodes$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((values: ICountryCodeSelectInterface[]) => {
        this.countryPhoneCodes = values;
      });
    this.languageService
      .getActiveLanguage$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((lang: Languages) => this.setPhoneByLanguage(lang));
  }

  ngAfterViewChecked(): void {
    // if (this.countryCodeListRef) {
    //   this.countryCodeListRef.nativeElement.scrollTop = (
    //     document.getElementsByClassName('PhoneCodeSelect-countryCodeSelected')[0] as HTMLElement
    //   ).offsetTop;
    // }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  selectCountryCode(value: ICountryCodeSelectInterface): void {
    this.selectedCountryCode = value;
    this.selectedCode.emit(value);
  }

  closeSelect(): void {
    if (this.isCountryCodesListVisible) {
      this.isCountryCodesListVisible = false;
    }

    this.cdRef.detectChanges();
  }

  trackByCountryCode(index: number, countryCodeSelected: ICountryCodeSelectInterface): string {
    return countryCodeSelected.dialCode;
  }

  onListClick(): void {
    this.isCountryCodesListVisible = !this.isCountryCodesListVisible;
    this.cdRef.detectChanges();
  }

  private setPhoneByLanguage(lang: Languages): void {
    const langKey: string = Object.keys(Languages).find((key: string) => Languages[key] === lang);

    if (langKey) {
      this.selectedCountryCode = CONSTANTS.PHONE_CODE[langKey];
      this.selectedCode.emit(this.selectedCountryCode);
    }
  }

  private getCountriesPhoneCodes$(): Observable<ICountryCodeSelectInterface[]> {
    return this.http.get('./assets/countryCodes.json').pipe(
      map((res: any) => {
        const array: any = res.map((value: any) => ({ dialCode: value.dial_code, icon: value.code.toLowerCase() }));

        return array.sort((a: any, b: any) => +a.dialCode.split('+')[1] - +b.dialCode.split('+')[1]);
      }),
      catchError((error: any) => error)
    );
  }
}
