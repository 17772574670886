import { AbstractControl } from '@angular/forms';
import { IDictionary } from './common.interfaces';

// eslint-disable-next-line
const EMAIL_REG_EX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_REG_EX = /^\[0-9\-\s]+$/;
export class CustomValidators {
  static email(control: AbstractControl): IDictionary<any> {
    const email = control.value;

    if (EMAIL_REG_EX.test(email)) {
      return null;
    }

    return { email: true };
  }

  static optionsSelected(control: AbstractControl): IDictionary<any> {
    const isAllFalsy: boolean = control.value.find((value: boolean) => value);

    if (!isAllFalsy) {
      return {
        anyOptionSelected: true,
      };
    }

    return null;
  }

  static minLengthWithoutSpace(minCharCount: number): IDictionary<any> {
    return (control: AbstractControl) => {
      const num = control.value.length;
      const startSpace = /^\s/;
      const endSpace = / $/;

      if (isNaN(num) || num < minCharCount || startSpace.test(control.value) || endSpace.test(control.value)) {
        return { minCharCount: true };
      }

      return null;
    };
  }
}
