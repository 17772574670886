import { Injectable } from '@angular/core';
import { CONSTANTS_ERRORS } from '../../../common/constants/errors/errors.constants';

@Injectable()
export class GenericErrorHandlerService {
  constructor() {}

  handleApiError(error: any): void {
    switch (error.status) {
      case CONSTANTS_ERRORS.HTTP_404: {
        console.error('Error 404');
        break;
      }
      case CONSTANTS_ERRORS.HTTP_500: {
        console.error('Error 500');
        break;
      }
      default:
        break;
    }
  }
}
