import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { LanguageSelectorComponent } from './_language-selector/language-selector.component';
import { LanguageDropdownComponent } from './language-dropdown/language-dropdown.component';
import { LanguageSelectorService } from './language-selector.service';
import { LanguageSingleComponent } from './language-single/language-single.component';

@NgModule({
  declarations: [LanguageSelectorComponent, LanguageSingleComponent, LanguageDropdownComponent],
  imports: [CommonModule, SharedModule, PortalModule, OverlayModule],
  providers: [LanguageSelectorService],
  exports: [LanguageSelectorComponent],
})
export class LanguageSelectorModule {}
