import { HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ExtendedGetResult } from '@fingerprintjs/fingerprintjs-pro';
import { BehaviorSubject, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, takeUntil } from 'rxjs/operators';
import { CONSTANTS } from '../../common/constants/constants';
import { CONSTANTS_ERRORS } from '../../common/constants/errors/errors.constants';
import { CONSTANTS_ROUTING } from '../../common/constants/routing/routing.constants';
import { IFingerprintDataDTO } from '../../core/providers/fingerprint/fingerprint.interface';
import { HttpService } from '../../core/providers/http/http.service';
import { LayoutService } from '../../core/providers/layout/layout.service';
import { ServerEnvironmentService } from '../../core/providers/server-envionment/server-environment.service';
import { UtilsService } from '../../core/providers/util/utils.service';
import { FilesService } from '../advertisement/files.service';
import { CONTACT_GUEST_CONSTANTS } from '../contact-guest/contact-guest.constants';
import { DEMOGRAPHICS_CONSTANTS } from '../demographics/demographics.constants';
import { DemographicsService } from '../demographics/demographics.service';
import { IDemographicsDto } from '../demographics/interfaces/demographics-dto.interface';
import { ONE_MORE_QUESTION_CONTANTS } from '../one-more-question/one-more-question.constants';
import { WHEEL_OF_FORTUNE_ROUTING_CONSTANTS } from '../wheel-of-fortune/wheel-of-fortune-routing.constants';
import { WheelOfFortuneService } from '../wheel-of-fortune/wheel-of-fortune.service';
import { ISurveyContactGuestDto } from './interfaces/survey-contact-guest-dto.interface';
import { ISurveyDTO } from './interfaces/survey-dto.interface';
import { ISurvey, ISurveyResponse } from './interfaces/survey.interface';
import { SurveyStates } from './survey-states.enum';
import { CONSTANTS_SURVEY } from './survey.constants';
import { venues_without_fraud_detection } from './venues-exception';

@Injectable({
  providedIn: 'root',
})
export class SurveyService implements OnDestroy {
  private surveyState$: BehaviorSubject<SurveyStates> = new BehaviorSubject(null);
  private surveyId: string;
  private surveyDTO: ISurveyDTO;
  private survey: ISurvey;
  private askPermissionToContact = false;
  private surveyContactGuestDto: ISurveyContactGuestDto;
  private onDestroy$: Subject<void> = new Subject<void>();
  private askOneMoreQuestion = false;

  constructor(
    private httpService: HttpService,
    private router: Router,
    private wheelOfFortuneService: WheelOfFortuneService,
    private filesService: FilesService,
    private layoutService: LayoutService,
    private serverEnvironmentService: ServerEnvironmentService,
    private demographicsService: DemographicsService,
    private utilsService: UtilsService
  ) {}

  fetchSurvey$(id: string, fingerprint: string): Observable<ISurvey> {
    const params = new HttpParams().set('f', fingerprint);

    return this.httpService.get$(`${CONSTANTS_SURVEY.ENDPOINTS.FETCH_SURVEY}/${id}`, { params }).pipe(
      catchError(error => {
        if (error.status === CONSTANTS.ERRORS.HTTP_404) {
          this.setSurveyState(SurveyStates.notFound);
        }
        if (error.status === CONSTANTS_ERRORS.HTTP_403) {
          this.setSurveyState(SurveyStates.back);
        }

        return throwError(error);
      })
    );
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  saveSurvey$(surveyDTO: ISurveyDTO): Observable<ISurveyResponse> {
    localStorage.setItem(CONSTANTS.LOCALSTORAGE_KEYS.SURVEY_TOKEN, surveyDTO.token);

    return this.httpService.post$(CONSTANTS_SURVEY.ENDPOINTS.SAVE_SURVEY, surveyDTO).pipe(
      catchError(error => {
        if (error.status === CONSTANTS_ERRORS.HTTP_410) {
          this.blockHappyWheel();
        } else if (error.status === CONSTANTS_ERRORS.HTTP_403) {
          this.setSurveyState(SurveyStates.back);
          this.router.navigate([`${CONSTANTS_ROUTING.ROUTES.ADD.MAIN}/${CONSTANTS_ROUTING.ROUTES.ADD.BACK}`]);
        } else {
          this.setSurveyState(SurveyStates.error);
        }

        return throwError(error);
      })
    );
  }

  saveSurveyContactGuest$(surveyContactGuestDto: ISurveyContactGuestDto): Observable<any> {
    return this.httpService
      .put$(`${CONSTANTS_SURVEY.ENDPOINTS.SAVE_SURVEY}/${CONSTANTS_SURVEY.ENDPOINTS.TYPES.CONTACT_PERMISSION}`, surveyContactGuestDto)
      .pipe(
        catchError(error => {
          this.setSurveyState(SurveyStates.error);

          return throwError(error);
        })
      );
  }

  getSurveyState$(): Observable<SurveyStates> {
    return this.surveyState$.pipe(filter(state => state !== null));
  }

  setSurveyState(state: SurveyStates) {
    this.surveyState$.next(state);
    window.scrollTo(0, 0);
  }

  setSurveyId(id: string) {
    this.surveyId = id;
  }

  getSurveyId() {
    return this.surveyId;
  }

  setSurvey(survey: ISurvey) {
    this.survey = survey;
    this.demographicsService.setDemographicsConfig(this.survey.demographics);
    this.wheelOfFortuneService.setWheelConfig(this.survey.wheel);
    this.wheelOfFortuneService.setSurveyToken(this.surveyId);
  }

  getSurvey() {
    return this.survey;
  }

  getAskOneMoreQuestion(): boolean {
    return this.askOneMoreQuestion;
  }

  setAskOneMoreQuestion(value: boolean): void {
    this.askOneMoreQuestion = value;
  }

  getAskPermissionToContact() {
    return this.askPermissionToContact;
  }

  setSurveyDTO(survey: ISurveyDTO) {
    this.surveyDTO = survey;
  }

  getSurveyDTO() {
    return this.surveyDTO;
  }

  saveSurveyContactGuestAndRedirect(surveyContactGuestDto: ISurveyContactGuestDto) {
    this.saveSurveyContactGuest$(surveyContactGuestDto)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.redirectAfterSave());
  }

  setSurveyContactGuest(surveyContactGuestDto: ISurveyContactGuestDto) {
    this.surveyContactGuestDto = surveyContactGuestDto;
  }

  getSurveyContactGuest(): ISurveyContactGuestDto {
    return this.surveyContactGuestDto;
  }

  saveSurveyAndRedirect(visitorId: string) {
    this.setVenueDataInLocalStorage();
    this.surveyDTO.visitorId = visitorId;
    this.saveSurvey$(this.surveyDTO)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(response => {
        this.askPermissionToContact = response.askPermissionToContact;

        if (this.askOneMoreQuestion) {
          this.router.navigate([`${ONE_MORE_QUESTION_CONTANTS.ROUTES.MAIN}/${this.survey.statementSet.venueUuid}`]);
        } else {
          this.navigateToPermissionToContact();
        }
      });
  }

  navigateToPermissionToContact(): void {
    if (this.askPermissionToContact) {
      this.router.navigate([`${CONTACT_GUEST_CONSTANTS.ROUTES.MAIN}/${CONTACT_GUEST_CONSTANTS.ROUTES.STATUSES.ASK}`]);
    } else {
      this.goToNextSteps();
    }
  }

  redirectAfterSave() {
    this.askPermissionToContact = false;
    this.askOneMoreQuestion = false;

    if (this.survey.advertisement.advertisementFileUuid) {
      this.getAndSetAdvertisement();
    } else {
      this.finishTheSurvey();
    }
  }

  sendFingerprintDataAnalytics(token: string, data: ExtendedGetResult, isBlocked: boolean, checkInUuid?: string) {
    const fingerprintDTO: IFingerprintDataDTO = {
      token,
      data,
      isBlocked,
      checkInUuid,
    };

    return this.httpService.post$(CONSTANTS_SURVEY.ENDPOINTS.SAVE_FINGERPRINT_DATA, fingerprintDTO);
  }

  saveDemographics(demographicsDto: IDemographicsDto) {
    demographicsDto.token = this.surveyId;
    this.saveSurveyDemographics$(demographicsDto)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.goToHappyWheelOrAdvertisement());
  }

  goToHappyWheelAfterDemographicsCanceling() {
    if (this.survey.wheel.isWheelDemographicsEnabled) {
      this.router.navigate([`${CONSTANTS_ROUTING.ROUTES.ADD.MAIN}/${CONSTANTS_ROUTING.ROUTES.ADD.THANK_YOU}`]);
    } else {
      this.goToHappyWheelOrAdvertisement();
    }
  }

  goToHappyWheelOrAdvertisement() {
    if (this.survey.wheel.isEnabled) {
      if (this.hasNoVenueFraudDetection(this.survey.statementSet.venueUuid)) {
        this.wheelOfFortuneService.setWheelOfFortuneBlocked(false);
      } else {
        // Let user spin the wheel even when the survey is already answered
        this.wheelOfFortuneService.setWheelOfFortuneBlocked(false);
        // this.checkAndSetWheelOfFortuneBlockStatus();
      }

      this.router.navigate([WHEEL_OF_FORTUNE_ROUTING_CONSTANTS.ROUTES.HAPPY_WHEEL.MAIN]);
    } else {
      this.router.navigate([`${CONSTANTS_ROUTING.ROUTES.ADD.MAIN}/${CONSTANTS_ROUTING.ROUTES.ADD.THANK_YOU}`]);
    }
  }

  checkLocalStorage(id: string): boolean {
    if (this.hasNoVenueFraudDetection(id)) {
      return true;
    }
    const key = `${CONSTANTS.LOCALSTORAGE_KEYS.SUR}${id}`;
    const data = JSON.parse(localStorage.getItem(key));

    if (data && data.uuid) {
      if (data.uuid === id) {
        const expiresDate: Date = new Date(data.dateTime);
        const actualDate: Date = new Date();

        return actualDate.getTime() > expiresDate.getTime();
      }

      return true;
    }

    return true;
  }

  hasNoVenueFraudDetection(venueUuid: string): boolean {
    return !!venues_without_fraud_detection.find(uuid => uuid === venueUuid);
  }

  private goToNextSteps() {
    if (this.survey.advertisement.advertisementFileUuid) {
      this.getAndSetAdvertisement();
    } else {
      this.redirectAfterSave();
    }
  }

  private blockHappyWheel() {
    this.wheelOfFortuneService.setWheelOfFortuneBlocked(true);

    if (this.survey.advertisement.advertisementFileUuid) {
      this.getAndSetAdvertisement();
    } else {
      this.finishTheSurvey();
    }
  }

  private saveSurveyDemographics$(demographicsDto: IDemographicsDto): Observable<any> {
    return this.httpService
      .put$(`${CONSTANTS_SURVEY.ENDPOINTS.SAVE_SURVEY}/${CONSTANTS_SURVEY.ENDPOINTS.TYPES.DEMOGRAPHICS}`, demographicsDto)
      .pipe(
        catchError(error => {
          this.setSurveyState(SurveyStates.error);

          return throwError(error);
        })
      );
  }

  private finishTheSurvey() {
    this.setSurveyState(SurveyStates.complete);

    if (
      this.survey.demographics.isEnabled &&
      (this.survey.demographics.shouldAskForAgeGroup || this.survey.demographics.shouldAskForGender)
    ) {
      this.goToDemographicsForm();
    } else {
      this.goToHappyWheelOrAdvertisement();
    }
  }

  private goToDemographicsForm() {
    if (this.survey.wheel.isWheelDemographicsEnabled && this.survey.wheel.isEnabled) {
      this.router.navigate([`${DEMOGRAPHICS_CONSTANTS.ROUTES.MAIN}/${DEMOGRAPHICS_CONSTANTS.ROUTES.FORMS.WHEEL_OF_FORTUNE}`]);
    } else {
      this.router.navigate([DEMOGRAPHICS_CONSTANTS.ROUTES.MAIN]);
    }
  }

  private getAndSetAdvertisement(): void {
    this.finishTheSurvey();
  }

  private checkAndSetWheelOfFortuneBlockStatus() {
    let accountsList: {
      id: string;
      expires: string;
    }[] = JSON.parse(localStorage.getItem('accounts'));

    const { id } = this.serverEnvironmentService.getServerEnvironmentConfigObject().account;
    const twelveHour = 12;
    const fourHour = 4;

    // eslint-disable-next-line
    if (id) {
      if (accountsList) {
        const selectedAccount = accountsList.find(account => account.id === id);

        if (selectedAccount) {
          const expiresDate: Date = new Date(selectedAccount.expires);
          const actualDate: Date = new Date();

          if (actualDate.getTime() > expiresDate.getTime()) {
            selectedAccount.expires = this.utilsService.getNewDateAfterHours(twelveHour);
            this.wheelOfFortuneService.setWheelOfFortuneBlocked(false);
          } else {
            this.wheelOfFortuneService.setWheelOfFortuneBlocked(true);
          }
        } else {
          this.wheelOfFortuneService.setWheelOfFortuneBlocked(false);
          accountsList.push({
            id,
            expires: this.utilsService.getNewDateAfterHours(fourHour),
          });
        }
      } else {
        accountsList = [];
        this.wheelOfFortuneService.setWheelOfFortuneBlocked(false);
        accountsList.push({
          id,
          expires: this.utilsService.getNewDateAfterHours(fourHour),
        });
      }

      localStorage.setItem('accounts', JSON.stringify(accountsList));
    }
  }

  private setVenueDataInLocalStorage() {
    if (this.hasNoVenueFraudDetection(this.survey.statementSet.venueUuid)) {
      return;
    }
    const fourHour = 4;
    const key = `${CONSTANTS.LOCALSTORAGE_KEYS.SUR}${this.survey.statementSet.venueUuid}`;
    const data = {
      uuid: this.survey.statementSet.venueUuid,
      dateTime: this.utilsService.getNewDateAfterHours(fourHour),
    };

    localStorage.setItem(key, JSON.stringify(data));
  }
}
