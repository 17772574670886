export const CONSTANTS_ROUTING = {
  ROUTES: {
    SURVEY: 'questions/:id',
    VOUCHER: 'voucher/:id',
    VENUES: 'venues/:id',
    CLOSED: 'closed/:id',
    NOT_FOUND: 'notfound',
    VENUE_DISABLED: 'venue-disabled',
    ERROR: 'error',
    GHOST: 'ghost',
    ADD: {
      MAIN: 'add',
      THANK_YOU: 'ad-thank-you',
      WINNER: 'ad-winner',
      LOOSER: 'ad-no-prize',
      BACK: 'ad-back-already',
      INCOGNITO: 'incognito',
    },
  },
};
