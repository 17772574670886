import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CONSTANTS } from '../../../../common/constants/constants';
import { QR_LANDING_CONSTANTS } from '../../../../components/qr-landing/qr-landing.constants';
import { HttpService } from '../../../../core/providers/http/http.service';
import { LayoutService } from '../../../../core/providers/layout/layout.service';

@Component({
  selector: 'app-final-page',
  templateUrl: './final-page.component.html',
  styleUrls: ['./final-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinalPageComponent implements OnInit, OnDestroy {
  @Input() headerText: string;
  @Input() message: string;
  isImageLoaded = false;
  token: string;
  advUrl = '';
  private subscriptions: Subscription = new Subscription();

  constructor(private layoutService: LayoutService, private ref: ChangeDetectorRef, private router: Router) {}

  ngOnInit(): void {
    this.token = localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEYS.PORTAL_TOKEN);
    this.subscriptions.add(
      this.layoutService.activeAdvertisementURL$.subscribe(() => {
        this.ref.detectChanges();
      })
    );
    this.subscriptions.add(
      this.layoutService.activeAdvertisementImageURL$.subscribe((url: string) => {
        this.advUrl = url;
        this.ref.detectChanges();
      })
    );
  }

  getUrl(): string {
    return this.layoutService.getAdvertisementUrl();
  }

  goTotAdvertisementUrl(): void {
    if (this.getUrl()) {
      window.location.href = HttpService.addHttp(this.getUrl());
    }
  }

  onImageLoad(): void {
    this.isImageLoaded = true;
  }

  goToPortal(): void {
    this.router.navigate([`${QR_LANDING_CONSTANTS.ROUTES.MAIN}/${this.token}`]);
  }

  onImageError(): void {
    this.advUrl = this.layoutService.getDefaultAdvertisementUrl();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
