import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../../../core/providers/layout/layout.service';

@Component({
  selector: 'app-basic-page',
  templateUrl: './basic-page.component.html',
  styleUrls: ['./basic-page.component.scss'],
})
export class BasicPageComponent implements OnInit {
  @Input() headerText: string;
  @Input() message: string;
  @Input() subMessage: string;
  @Input() useLayoutColor = true;

  color: string;

  constructor(public layoutService: LayoutService, private cdf: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.color = this.useLayoutColor ? this.layoutService.useLayoutColor() : 'rgba(0,0,0)';
    this.cdf.detectChanges();
  }
}
