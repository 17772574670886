import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CONSTANTS_COLORS } from '../../../common/constants/colors/layout-colors';
import { CONSTANTS } from '../../../common/constants/constants';
import { FilesService } from '../../../components/advertisement/files.service';

const LAYOUT_DEFAULT_LOGO_URL = 'assets/img/foodback-logo.png';
const LAYOUT_DEFAULT_ADV = 'assets/img/default-adv.png';

@Injectable()
export class LayoutService implements OnDestroy {
  activeLayoutColor$: BehaviorSubject<string> = new BehaviorSubject(CONSTANTS_COLORS.BRAND);
  activeLayoutLogoURL$: BehaviorSubject<string> = new BehaviorSubject('');
  activeAdvertisementImageURL$: Subject<string> = new BehaviorSubject('');
  activeAdvertisementURL$: Subject<string> = new BehaviorSubject('');
  loadedLayoutData$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loadedLogo$: Subject<boolean> = new Subject();

  private layoutColorHex = CONSTANTS_COLORS.BRAND;
  private layoutLogoURL = '';
  private advertisementURL = '';
  private advertisementImageURL = '';
  private onDestroy$ = new Subject<void>();

  constructor(private filesService: FilesService) {
    this.setLayoutDefaultLogoUrl();
  }

  useLayoutColor(alpha = 1): string {
    // eslint-disable-next-line no-magic-numbers
    const r = parseInt(this.layoutColorHex.slice(1, 3), 16);
    // eslint-disable-next-line no-magic-numbers
    const g = parseInt(this.layoutColorHex.slice(3, 5), 16);
    // eslint-disable-next-line no-magic-numbers
    const b = parseInt(this.layoutColorHex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }

    return `rgba(${r}, ${g}, ${b})`;
  }

  getLogoLayoutUrl(): string {
    return this.layoutLogoURL;
  }

  getAdvertisementUrl(): string {
    return this.advertisementURL;
  }

  getAdvertisementImageUrl(): string {
    return this.advertisementImageURL;
  }

  setLayoutDefaultColor(color: string): void {
    const colorCode: string = color ? `#${color}` : CONSTANTS.COLORS.BRAND;

    this.layoutColorHex = colorCode;
    this.activeLayoutColor$.next(colorCode);
    this.setInputOutlineColor(colorCode);
    this.setChipsColor(colorCode);
    this.setLinksColors(colorCode);
  }

  getDefaultColor(): string {
    return this.layoutColorHex;
  }

  setAdvertisementURL(imageUuid: string, advertisementURL: string): void {
    const url: string = this.filesService.getUrl(imageUuid, CONSTANTS.IMAGES.ADVERTISEMENT_WIDTH);

    this.advertisementURL = advertisementURL;
    this.advertisementImageURL = url;
    this.activeAdvertisementURL$.next(advertisementURL);
    this.activeAdvertisementImageURL$.next(url);
  }

  setVenueLogo(logoFileUuid: string) {
    this.layoutLogoURL = '';

    // eslint-disable-next-line
    if (logoFileUuid) {
      const url: string = this.filesService.getUrl(logoFileUuid, null, CONSTANTS.IMAGES.LOGO_HEIGHT);

      this.setLayoutLogoUrl(url);
    } else {
      this.setLayoutDefaultLogoUrl();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setLayoutLogoUrl(url: string): void {
    this.layoutLogoURL = url;
    this.activeLayoutLogoURL$.next(url);
  }

  setLayoutDefaultLogoUrl(): void {
    this.layoutLogoURL = LAYOUT_DEFAULT_LOGO_URL;
    this.activeLayoutLogoURL$.next(LAYOUT_DEFAULT_LOGO_URL);
  }

  getDefaultLogoUrl(): string {
    return LAYOUT_DEFAULT_LOGO_URL;
  }

  getDefaultAdvertisementUrl(): string {
    return LAYOUT_DEFAULT_ADV;
  }

  private setInputOutlineColor(color: string): void {
    const editCSS: HTMLStyleElement = document.createElement('style');

    // eslint-disable-next-line
    editCSS.innerHTML = `input:focus, select:focus, textarea:focus, .InputSelected { outline: none; box-shadow: 0 0 0 2px ${color} !important; border-radius: 2px; } .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background { background: ${color} !important; } .mat-radio-button.mat-primary .mat-radio-inner-circle, .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple { background-color: ${color}; } .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle, .mat-radio-outer-circle { border-style: dotted; } .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle { border-style: solid !important; border-color: ${color}; }`;
    document.body.style.setProperty('--primary-color', 'blue');
    document.body.style.setProperty('--accent-color', 'blue');
    document.body.appendChild(editCSS);
  }

  private setChipsColor(color: string): void {
    const editCSS: HTMLStyleElement = document.createElement('style');

    // eslint-disable-next-line
    editCSS.innerHTML = `.mat-mdc-chip-selected { color: black; background: ${
      `${color  }24 `
    } !important; border: 2px solid ${color}; !important;} .mat-mdc-chip:hover:not(.mat-mdc-chip-selected) { background-color: ${color }24 !important; } .mdc-radio__native-control:checked+.mdc-radio__background .mdc-radio__inner-circle { border-color: ${color} !important} .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle { color: ${color} !important; border-color: ${color} !important} .chips-check-icon { background: ${color}; }`;
    document.body.style.setProperty('--primary-color', 'blue');
    document.body.style.setProperty('--accent-color', 'blue');
    document.body.appendChild(editCSS);
  }

  private setLinksColors(color: string) {
    const editCSS: HTMLStyleElement = document.createElement('style');

    // eslint-disable-next-line
    editCSS.innerHTML = `.gdprLink, .link { color: ${color}`;
    editCSS.innerHTML = `.link, .link { color: ${color}`;
    document.body.style.setProperty('--primary-color', 'blue');
    document.body.style.setProperty('--accent-color', 'blue');
    document.body.appendChild(editCSS);
  }
}
