import { Injectable } from '@angular/core';
import { detectIncognito } from 'detectincognitojs';

@Injectable({
  providedIn: 'root',
})
export class IncognitoService {
  private isPrivate = false;

  setIncognito(): void {
    detectIncognito().then(result => (this.isPrivate = result.isPrivate));
  }

  isInIncognito(): boolean {
    return this.isPrivate;
  }
}
