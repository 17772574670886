<div class="BasicPage container-padding">
  <h2 class="BasicPage-header" [style.color]="color">{{ headerText | translate }}</h2>
  <ng-container *ngIf="message">
    <span class="BasicPage-message dimmedText">{{ message | translate }}</span>
  </ng-container>
  <ng-container *ngIf="subMessage">
    <p class="dimmedText mt-20" [innerHTML]="subMessage | translate"></p>
  </ng-container>
  <ng-content></ng-content>
</div>
