import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { CONSTANTS_ERRORS } from '../common/constants/errors/errors.constants';
import { CONSTANTS_ROUTING } from '../common/constants/routing/routing.constants';
import { IncognitoService } from '../core/providers/incognito/incognito.service';
import { LayoutService } from '../core/providers/layout/layout.service';
import { venues_without_fraud_detection } from './survey/venues-exception';

@Injectable()
export class SurveyInterceptor implements HttpInterceptor {
  private venueUuid: string;

  constructor(
    private layoutService: LayoutService,
    private router: Router,
    private incognitoService: IncognitoService,
    private zone: NgZone
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((res: HttpResponse<any>) => this.handleRequest(res?.body)),
      catchError((err: any) => {
        this.handleRequest(err?.error);

        return throwError(err);
      })
    );
  }

  handleRequest(res: HttpResponse<any>): void {
    this.handleErrors(res);
    this.setGeneralSettings(res);
  }

  private handleErrors(error: any): void {
    switch (error?.errorCode) {
      case CONSTANTS_ERRORS.ERR_2403:
        this.zone.run(() => this.router.navigate([CONSTANTS_ROUTING.ROUTES.VENUE_DISABLED]));
        break;
      default:
        break;
    }
  }

  private setGeneralSettings(res: any): void {
    if (res?.campaign || res?.venueUuid || res?.statementSet?.venueUuid) {
      this.setVenueUuid(res);

      if (!this.hasNoVenueFraudDetection(this.venueUuid)) {
        this.checkAndBlockInIncognito(res);
      }
    }
    if (res?.advertisement) {
      this.layoutService.setAdvertisementURL(res.advertisement.advertisementFileUuid, res.advertisement.url);
    }
    if (res?.branding) {
      this.layoutService.setVenueLogo(res?.branding.logoFileUuid);
      this.layoutService.setLayoutDefaultColor(res?.branding.colorTint);
    }
    //campaign exception
    if (res && res.info && res.info.advertisement) {
      this.layoutService.setAdvertisementURL(res.info.advertisement.advertisementFileUuid, res.info.advertisement.url);
    }
    if (res && res.info && res?.info.branding) {
      this.layoutService.setVenueLogo(res.info.branding.logoFileUuid);
      this.layoutService.setLayoutDefaultColor(res.info.branding.colorTint);
    }
  }

  private checkAndBlockInIncognito(res: any): void {
    if (this.incognitoService.isInIncognito() && (res?.advertisement || res?.branding || res?.info?.branding || res?.info?.advertisement)) {
      // this.blockAppInIncognito();
    }
  }

  private blockAppInIncognito(): void {
    this.zone.run(() =>
      this.router.navigate([this.router.navigate([`${CONSTANTS_ROUTING.ROUTES.ADD.MAIN}/${CONSTANTS_ROUTING.ROUTES.ADD.INCOGNITO}`])])
    );
  }

  private hasNoVenueFraudDetection(venueUuid: string): boolean {
    return !!venues_without_fraud_detection.find(uuid => uuid === venueUuid);
  }

  private setVenueUuid(res: any): void {
    if (res?.campaign) {
      this.venueUuid = window.location.href.split('/')[4];
    } else if (res?.venueUuid) {
      this.venueUuid = res.venueUuid;
    } else if (res?.statementSet?.venueUuid) {
      this.venueUuid = res.statementSet.venueUuid;
    }
  }
}
