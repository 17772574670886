<div [class.SurveyStatement--overallExperience]="statement.category.overallExperience" class="SurveyStatement">
  <div class="SurveyStatementWrapper" [class.SurveyStatement__disable]="!isEnable">
    <div class="SurveyStatement-title" [ngStyle]="surveyTitleStyles">
      <span class="strike">{{ getStatementTitle() }}</span>
    </div>
    <div class="SurveyStatement__container">
      <div (mouseleave)="setHoverState(null)" class="SurveyStatementRatings">
        <div
          *ngFor="let i of [1, 2, 3, 4, 5, 6]"
          (click)="setRating(i)"
          (touchstart)="touchstart($event)"
          (touchmove)="touchmove($event)"
          (touchend)="touchend()"
          (mouseenter)="setHoverState(i)"
          class="SurveyStatementRatings__dotWrapper">
          <div
            [ngStyle]="surveyRatingsStyles"
            [ngClass]="getDotStyleClasses(i)"
            class="SurveyStatementRatings-dot SurveyStatementRatings-dot-border">
            <div class="SurveyStatementRatings-dotUnderlay" [ngClass]="getIndexClass(i)" [ngStyle]="surveyBackgroundStyles"></div>
            <ng-container [ngSwitch]="getDotType(i)">
              <app-svg-foodback-sad *ngSwitchCase="dotTypes.sad" [fill]="surveyDotStyles['background-color']"></app-svg-foodback-sad>
              <app-svg-foodback-arrow-right
                *ngSwitchCase="dotTypes.between"
                [fill]="surveyDotStyles['background-color']"></app-svg-foodback-arrow-right>
              <app-svg-foodback-happy *ngSwitchCase="dotTypes.happy" [fill]="surveyDotStyles['background-color']"></app-svg-foodback-happy>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="SurveyStatementRatings__dotWrapper SurveyStatementRatings__disableContainer" *ngIf="!isSkippingMode">
        <div
          class="SurveyStatementRatings-dot SurveyStatementRatings__disableContainerBorder"
          *ngIf="!statement.category.overallExperience; else emptyContainer"
          (click)="enableStatusChange()">
          <ng-container [ngSwitch]="isEnable">
            <app-svg-foodback-disable *ngSwitchCase="true" fill="rgba(146, 146, 146)"></app-svg-foodback-disable>
            <app-svg-foodback-enable *ngSwitchCase="false" fill="rgba(146, 146, 146)"></app-svg-foodback-enable>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #emptyContainer>
  <div class="empty-container"></div>
</ng-template>
