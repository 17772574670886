// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  isLocal: true,
  backendURL: 'https://api.dev.devfoodback.com/api/v1',
  production: false,
  fingerprintEndpoint: 'https://api.fpjs.io/',
  fingerprintToken: 'AZb649INhm8d7EgjN1ZK',
  translationPath: './assets/i18n',
  buildNumber: 'BUILD_NUMBER',
  fpQA: 'FP_QA',
  alHp: '1s845a9823df24fc',
  fH: 2110972834,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
