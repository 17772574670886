import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CONSTANTS_LOCALSTORAGE_KEYS } from '../../../common/constants/localstorage-keys/localstorage-keys.constants';
import { Languages } from '../../../common/enums/languages.enum';
import { ILanguage } from '../../../common/interfaces/language.interface';
import { ISurveyTranslation } from '../../../components/survey/interfaces/survey-translation.interface';

@Injectable()
export class LanguageService {
  private availableLanguages$: BehaviorSubject<Languages[]>;

  private activeLanguage$: BehaviorSubject<Languages>;
  private availableLanguages: Languages[] = [];
  private reloadLang$: Subscription;

  constructor(private translateService: TranslateService) {
    const defaultAvailableLanguages = [];
    const selectedLanguage = this.getBrowserLang();

    for (const key of Object.keys(Languages)) {
      defaultAvailableLanguages.push(Languages[key]);
    }

    this.activeLanguage$ = new BehaviorSubject(selectedLanguage);
    this.availableLanguages$ = new BehaviorSubject(defaultAvailableLanguages);
    // this.translateService.setDefaultLang(this.getTranslationFileName(selectedLanguage));
    this.translateService.use(this.getTranslationFileName(selectedLanguage));
  }

  setAvailableLanguages(languagesCodes: string[]) {
    this.availableLanguages = languagesCodes as Languages[];
    this.availableLanguages$.next(languagesCodes as Languages[]);
  }

  getAvailableLanguages$(): BehaviorSubject<Languages[]> {
    return this.availableLanguages$;
  }

  getActiveLanguage$(): Observable<Languages> {
    return this.activeLanguage$.pipe(filter(language => language !== null));
  }

  setAppLanguage(language: Languages) {
    const storedLanguage = this.saveOrGetLanguageFromStore(language);

    this.activateLanguage(storedLanguage);
  }

  getDefaultLanguage(languages: ILanguage[]): ILanguage {
    return languages.find((lang: ILanguage) => lang.isDefault);
  }

  activateLanguage(language: Languages): void {
    localStorage.setItem(CONSTANTS_LOCALSTORAGE_KEYS.LANG, language);

    if (this.reloadLang$) {
      this.reloadLang$.unsubscribe();
    }

    this.reloadLang$ = this.translateService.reloadLang(this.getTranslationFileName(language)).subscribe(() => {
      this.translateService.use(this.getTranslationFileName(language));
      this.activeLanguage$.next(language);
    });
  }

  getTranslationFileName(language: Languages): string {
    const selectedLang = language === Languages.britishEnglish ? Languages.britishEnglish : language;
    const langContent = selectedLang.split('-');
    const langPrefix = langContent[0];
    const suffix = langContent.length > 1 ? langContent[1].toUpperCase() : null;

    return suffix ? `takeaway_strings.${langPrefix}-${suffix}` : `takeaway_strings.${langPrefix}`;
  }

  findTranslatedPhraseInLanguage(language: Languages, translations: ISurveyTranslation[]): string {
    const translation: ISurveyTranslation = translations.find((item: ISurveyTranslation) => item.lang === language);

    return translation ? translation.value : this.getDefaultTranslation(translations);
  }

  private getDefaultTranslation(translations: ISurveyTranslation[]): string {
    const translation: ISurveyTranslation = translations.find((item: ISurveyTranslation) => item.lang === Languages.britishEnglish);

    return translation ? translation.value : '';
  }

  private saveOrGetLanguageFromStore(language: Languages): Languages {
    const storedLang = this.availableLanguages.includes(localStorage.getItem(CONSTANTS_LOCALSTORAGE_KEYS.LANG) as Languages)
      ? localStorage.getItem(CONSTANTS_LOCALSTORAGE_KEYS.LANG)
      : null;
    const selectLang = storedLang || language || this.getBrowserLang();

    if (selectLang && Object.values(Languages).includes(selectLang as Languages)) {
      return selectLang as Languages;
    }

    localStorage.setItem(CONSTANTS_LOCALSTORAGE_KEYS.LANG, language);

    return language;
  }

  private getBrowserLang(): Languages {
    let lang = null;

    Object.values(Languages).forEach(value => {
      if (!lang && value.includes(navigator.language)) {
        lang = value;
      }
    });

    return lang ? lang : Languages.britishEnglish;
  }
}
