import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Languages } from '../../../common/enums/languages.enum';
import { IDictionary } from '../../../common/interfaces/dictionary.interface';
import { ISurveyStatement } from '../../../components/survey/interfaces/survey-statement.interface';
import { ISurveyTranslation } from '../../../components/survey/interfaces/survey-translation.interface';
import { LayoutService } from '../../../core/providers/layout/layout.service';
import { SurveyDotTypes } from './survey-dot-types.enum';

const OPACITY_DOT = 0.5;
const OPACITY_RATING = 0.05;
const TMP_20 = 20;
const TMP_2 = 2;

@Component({
  selector: 'app-sur-survey-statement',
  templateUrl: './survey-statement.component.html',
  styleUrls: ['./survey-statement.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SurveyStatementComponent implements OnChanges {
  @Input() control: FormControl;
  @Input() statement: ISurveyStatement;
  @Input() language: Languages;
  @Input() isSkippingMode = false;

  surveyDotStyles = {
    'background-color': this.layoutService.useLayoutColor(OPACITY_DOT),
    'border-color': this.layoutService.useLayoutColor(),
  };
  surveyBackgroundStyles = {
    'background-color': this.layoutService.useLayoutColor(),
    'border-color': this.layoutService.useLayoutColor(),
  };
  surveyTitleStyles = {
    'font-size': '16px',
  };
  isEnable = true;
  readonly surveyRatingsStyles = {
    'background-color': this.layoutService.useLayoutColor(OPACITY_RATING),
    'border-color': this.layoutService.useLayoutColor(),
  };
  readonly dotTypes = SurveyDotTypes;
  private readonly maxRating = 6;
  private hoveredDotNumber: number | null;
  private isTouching = false;
  private startIndex = 0;
  private clientYStarted = 0;

  constructor(public layoutService: LayoutService) {}

  setRating(dotNumber: number) {
    if (!this.isTouching && this.isEnable) {
      this.control.setValue(dotNumber);
    } else {
      this.isEnable = true;
    }
  }

  getSelectedRating(): number {
    return this.control.value;
  }

  getDotStyleClasses(dotNumber: number): IDictionary<boolean> {
    const filled: boolean = this.isDotFilled(dotNumber);

    return {
      'SurveyStatementRatings-dot--hovered': this.isDotHovered(dotNumber),
      'SurveyStatementRatings-dot--filled': filled,
      'SurveyStatementRatings-dot--maxScore': filled && dotNumber === this.maxRating,
      'SurveyStatementRatings-dot--minScore': filled && dotNumber === 1 && this.getSelectedRating() === 1,
    };
  }

  setHoverState(dotNumber: number) {
    if (this.isEnable) {
      this.hoveredDotNumber = dotNumber;
    }
  }

  getDotType(dotNumber: number): SurveyDotTypes {
    if (dotNumber === 1) {
      return SurveyDotTypes.sad;
    }
    if (dotNumber === this.maxRating) {
      return SurveyDotTypes.happy;
    }

    return SurveyDotTypes.between;
  }

  getStatementTitle(): string {
    const translation: ISurveyTranslation = this.statement.translations.find(trans => trans.lang === this.language);

    return translation ? translation.value : '';
  }

  getIndexClass(index: number) {
    return `SurveyStatementRating-${index}`;
  }

  touchstart(event: TouchEvent) {
    // eslint-disable-next-line
    if (this.isEnable) {
      this.isTouching = true;
      const myLocation = event.changedTouches[0];
      const realTarget = document.elementFromPoint(myLocation.clientX, myLocation.clientY);

      this.clientYStarted = myLocation.clientY;

      if (realTarget && realTarget.classList && realTarget.classList.length === TMP_2) {
        const splittedClassName = realTarget.classList[1].split('-');

        this.startIndex = +splittedClassName[1];
      }
    }
  }

  touchmove(event: TouchEvent) {
    // eslint-disable-next-line
    if (this.isEnable) {
      const myLocation = event.changedTouches[0];
      const realTarget = document.elementFromPoint(myLocation.clientX, myLocation.clientY);

      if (realTarget && realTarget.classList && realTarget.classList.length === TMP_2) {
        const clientYDiffrence: number = Math.abs(myLocation.clientY - this.clientYStarted);
        const splittedClassName = realTarget.classList[1].split('-');
        const nextIndex: number = +splittedClassName[1];

        if (nextIndex > 0 && this.startIndex !== nextIndex && clientYDiffrence < TMP_20) {
          this.control.setValue(nextIndex);
        }
      }
    }
  }

  touchend() {
    if (this.isEnable) {
      this.isTouching = false;
    }
  }

  enableStatusChange() {
    this.isEnable = !this.isEnable;

    if (!this.isEnable) {
      this.control.setValue(null);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // eslint-disable-next-line
    if (changes.statement && this.statement.category.overallExperience) {
      this.surveyDotStyles['background-color'] = this.layoutService.useLayoutColor(OPACITY_DOT);
      this.surveyDotStyles['border-color'] = this.layoutService.useLayoutColor();
      this.surveyBackgroundStyles['background-color'] = this.layoutService.useLayoutColor();
      this.surveyBackgroundStyles['border-color'] = this.layoutService.useLayoutColor();
      this.surveyTitleStyles['font-size'] = '18px';
    }
  }

  private isDotFilled(dotNumber: number): boolean {
    return this.getSelectedRating() !== null && dotNumber <= this.getSelectedRating();
  }

  private isDotHovered(dotNumber: number): boolean {
    return this.hoveredDotNumber !== null && dotNumber <= this.hoveredDotNumber;
  }
}
